import { Box, Button, Container, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactFooter from "../components/ContactFooter";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import PortfolioGrid from "../components/PortfolioGrid";
import ScrollComponent6LawsCore from "../components/ScrollComponent6LawsCore";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MagicText from "../DefinitionComponents/Complete-Components/MagicText";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";

export default function Targets() {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });
  return (
    <>
      <Helmet>
        <title>tokentus |  Investors</title>
        <meta
          name="description"
          content="You believe in us! Become a shareholder of tokentus and benefit financially from the massive rise of the global blockchain market. "
        />
        <link rel="canonical" href="https:/tokentus.com/investors" />
      </Helmet>

      <ImgTextModule
        src={
          "172413_business_people_having_a_meeting_around_a_table_in_a_high_rise_office_by_Hans_Peter_Schepp_Artgrid-HD_H264-HD-1280web.mp4"
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="95vh"
        mediaOpacity={1}
        bgColor="brand.white"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontWeight="normal" fontSize="xl">
                Investing in tokentus
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.blue"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                You believe in us.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={24}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
                We are investors. We are experts. We are insiders.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <IntroComponent2colMonochrome
        headline="The same motivation. "
        text1="We have a common goal. Investing into the blockchain market and profiting financially from it."
        text2=" You do not have the market overview, no technical expertise or simply not enough time to deal with it intensively. As a tokentus shareholder, you are automatically included."
      />
      <IntroComponent2colMonochrome
        headline="The same approach."
        text1="It is important that you can identify with the way tokentus invests. Then we fit together."
        text2="There are currently many offerings in the market with which you can invest into blockchain industry and the range of offers is getting bigger and bigger."
      />
      <Box id="sixCore"></Box>
      <ScrollComponent6LawsCore />
      <Container minW="100%" mt={0.5} p={0}>
        <MagicText
          text={
            "In order to profit from the development of the blockchain, you have to choose the right investment team for you, which convinces with a good investment strategy."
          }
          quoteOrigin="– Oliver Michel –"
          coloranimtext={"#ffffff"}
          fontSize={{ md: "6xl", base: "3xl" }}
          scrollLength={"250vh"}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc="405215_Golf_Ball_Hitting_Golf_Club_by_jmg-visuals_Artgrid-HD_H264-HD.mp4"
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={"brand.blue"}
          showProgressCircle={true}
          progressColor={"brand.white"}
          showProgressLine={true}
          progressLineColor={"brand.white"}
        />
      </Container>

      <IntroComponent2colMonochrome
        version="thirds"
        headline="Become a shareholder."
        text1="Our stocks can be purchased on Xetra and on the stock exchanges Frankfurt, Munich, and Berlin."
        text2="tokentus takes over the entire process from the selection of promising targets, through due diligence, the investment according to terms and conditions and the support of the portfolio companies to the exit. You can benefit from increasing shares prices and the paying of dividends, if tokentus develops successfully."
      />
      <Container variant="layoutContainer" mt={8}>
        <ContactFooter
          title="Are you ready ?"
          subtitle={"This is a special time. For special people."}
          name="Oliver Michel, CEO"
          email="contact@tokentus.com"
          phone="+49 175 72 22 351"
        />
      </Container>
      {/* CONTAINER QUADFEATURE */}

      <IntroComponent2colMonochrome
        version="thirds"
        headline="Our investments."
        text1="We have proven ourselves."
        text2="We are very proud of our portfolio. Our investments have an excellent reputation and enormous potential in the blockchain market. Patience is important."
      />
      <PortfolioGrid />

      <Container
        variant="layoutContainer"
        mt={0.5}
        mb={0.5}
        display="grid"
        gridGap={0.5}
        gridTemplateColumns={{
          lg: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
        <ImgTextModule
          minH="450px"
          w="100%"
          src={"lac4.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Investor Relations.
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  Information at your fingertips.
                </Text>
              </FadeInAnimation>
              <RouterLink isExternal={false} link={"/investorrelations"}>
                <Button
                  mt={4}
                  variant="solid"
                  bg="brand.green"
                  color="brand.darkgray"
                >
                  Investor Relations
                </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>

        <ImgTextModule
          minH="450px"
          w="100%"
          src={"header-master.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Experts.
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  From Seniority to Innovation.
                </Text>
              </FadeInAnimation>

              <RouterLink isExternal={false} link={"/team"}>
                <Button
                  mt={4}
                  variant="solid"
                  bg="brand.green"
                  color="brand.darkgray"
                >
                  Meet the team
                </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </Container>
    </>
  );
}
